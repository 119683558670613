@import '../../../styles/helpers'

.actions
    margin-top: 16px
    padding: 32px
    border-radius: 8px
    background: $n8
    +t
        margin-top: 56px
        padding: 0
        background: none
    +dark
        background: #18191D
        +t
            background: none

.list
    display: flex
    flex-wrap: wrap
    margin: -32px -16px 0
    +m
        flex-wrap: nowrap
        margin: 0 -32px
        overflow: auto
        overflow-x: auto
        -ms-overflow-style: none
        scrollbar-width: none
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
            display: none
        &:before,
        &:after
            content: ""
            flex-shrink: 0
            width: 32px
            height: 1px

.item
    display: flex
    flex: 0 0 calc(50% - 32px)
    width: calc(50% - 32px)
    margin: 32px 16px 0
    color: $n2
    +t
        flex-direction: column
    +m
        flex: 0 0 222px
        width: 222px
        margin: 0
        &:not(:last-child)
            margin-right: 16px
    +dark
        color: $n8

.preview
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 128px
    height: 144px
    margin-right: 32px
    padding: 12px
    border-radius: 16px
    +t
        width: 100%
        margin: 0 0 24px
    img
        max-width: 100%

.details
    display: flex
    flex-direction: column
    align-items: flex-start
    flex-grow: 1

.title
    margin-bottom: 8px
    +body-bold-1
    +m
        font-size: 16px

.content
    margin-bottom: 16px
    color: $n4

.button
    margin-top: auto
