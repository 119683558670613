.flag
  display: inline-block
  position: relative
  width: 16px
  height: 11px
  background: url("../../../public/images/flags/flags.3c4ef45a.png") no-repeat

.flag.flag-gu
  background-position: -96px -55px

.flag.flag-mn
  background-position: -208px -88px

.flag.flag-va
  background-position: -48px -154px

.flag.flag-tibet
  background-position: -32px -143px

.flag.flag-fo
  background-position: -64px -44px

.flag.flag-th
  background-position: -16px -143px

.flag.flag-tr
  background-position: -144px -143px

.flag.flag-tl
  background-position: -80px -143px

.flag.flag-kz
  background-position: -144px -77px

.flag.flag-zm
  background-position: -16px -165px

.flag.flag-uz
  background-position: -32px -154px

.flag.flag-dk
  background-position: -64px -33px

.flag.flag-scotland
  background-position: -176px -121px

.flag.flag-gi
  background-position: -224px -44px

.flag.flag-gy
  background-position: -128px -55px

.flag.flag-bj
  background-position: -112px -11px

.flag.flag-fr
  background-position: -80px -44px

.flag.flag-mo
  background-position: -224px -88px

.flag.flag-ir
  background-position: -112px -66px

.flag.flag-io
  background-position: -80px -66px

.flag.flag-tm
  background-position: -96px -143px

.flag.flag-ch
  background-position: -96px -22px

.flag.flag-mt
  background-position: -32px -99px

.flag.flag-nl
  background-position: -240px -99px

.flag.flag-gp
  background-position: -16px -55px

.flag.flag-im
  background-position: -48px -66px

.flag.flag-tv
  background-position: -176px -143px

.flag.flag-mu
  background-position: -48px -99px

.flag.flag-pe
  background-position: -96px -110px

.flag.flag-vi
  background-position: -112px -154px

.flag.flag-hn
  background-position: -176px -55px

.flag.flag-ss
  background-position: -128px -132px

.flag.flag-ae
  background-position: -16px 0

.flag.flag-td
  background-position: -240px -132px

.flag.flag-pw
  background-position: 0 -121px

.flag.flag-nu
  background-position: -32px -110px

.flag.flag-bt
  background-position: -208px -11px

.flag.flag-ms
  background-position: -16px -99px

.flag.flag-cv
  background-position: -240px -22px

.flag.flag-es
  background-position: -224px -33px

.flag.flag-mh
  background-position: -144px -88px

.flag.flag-la
  background-position: -160px -77px

.flag.flag-vn
  background-position: -128px -154px

.flag.flag-py
  background-position: -16px -121px

.flag.flag-br
  background-position: -176px -11px

.flag.flag-ye
  background-position: -224px -154px

.flag.flag-ie
  background-position: 0 -66px

.flag.flag-gh
  background-position: -208px -44px

.flag.flag-cg
  background-position: -80px -22px

.flag.flag-cu
  background-position: -224px -22px

.flag.flag-hu
  background-position: -224px -55px

.flag.flag-sg
  background-position: -224px -121px

.flag.flag-at
  background-position: -176px 0

.flag.flag-lk
  background-position: -224px -77px

.flag.flag-vu
  background-position: -144px -154px

.flag.flag-bo
  background-position: -160px -11px

.flag.flag-jo
  background-position: -208px -66px

.flag.flag-er
  background-position: -208px -33px

.flag.flag-za
  background-position: -256px -154px

.flag.flag-rs
  background-position: -80px -121px

.flag.flag-nr
  background-position: -16px -110px

.flag.flag-ls
  background-position: -256px -77px

.flag.flag-jm
  background-position: -192px -66px

.flag.flag-tz
  background-position: -208px -143px

.flag.flag-ki
  background-position: -16px -77px

.flag.flag-sj
  background-position: 0 -132px

.flag.flag-cz
  background-position: -16px -33px

.flag.flag-pg
  background-position: -128px -110px

.flag.flag-lv
  background-position: -32px -88px

.flag.flag-do
  background-position: -96px -33px

.flag.flag-lu
  background-position: -16px -88px

.flag.flag-no
  background-position: -256px -99px

.flag.flag-kw
  background-position: -112px -77px

.flag.flag-mx
  background-position: -96px -99px

.flag.flag-yt
  background-position: -240px -154px

.flag.flag-ly
  background-position: -48px -88px

.flag.flag-cy
  background-position: 0 -33px

.flag.flag-ph
  background-position: -144px -110px

.flag.flag-my
  background-position: -112px -99px

.flag.flag-sm
  background-position: -48px -132px

.flag.flag-et
  background-position: -240px -33px

.flag.flag-ru
  background-position: -96px -121px

.flag.flag-tj
  background-position: -48px -143px

.flag.flag-ai
  background-position: -64px 0

.flag.flag-pl
  background-position: -176px -110px

.flag.flag-kp
  background-position: -64px -77px

.flag.flag-uy
  background-position: -16px -154px

.flag.flag-gb
  background-position: -112px -44px

.flag.flag-gs
  background-position: -64px -55px

.flag.flag-kurdistan
  background-position: -96px -77px

.flag.flag-rw
  background-position: -112px -121px

.flag.flag-ec
  background-position: -128px -33px

.flag.flag-mm
  background-position: -192px -88px

.flag.flag-pa
  background-position: -80px -110px

.flag.flag-wales
  background-position: -160px -154px

.flag.flag-kg
  background-position: -256px -66px

.flag.flag-ve
  background-position: -80px -154px

.flag.flag-tk
  background-position: -64px -143px

.flag.flag-ca
  background-position: -16px -22px

.flag.flag-is
  background-position: -128px -66px

.flag.flag-ke
  background-position: -240px -66px

.flag.flag-ro
  background-position: -64px -121px

.flag.flag-gq
  background-position: -32px -55px

.flag.flag-pt
  background-position: -256px -110px

.flag.flag-tf
  background-position: -256px -132px

.flag.flag-ad
  background-position: 0 0

.flag.flag-sk
  background-position: -16px -132px

.flag.flag-pm
  background-position: -192px -110px

.flag.flag-om
  background-position: -64px -110px

.flag.flag-an
  background-position: -112px 0

.flag.flag-ws
  background-position: -192px -154px

.flag.flag-sh
  background-position: -240px -121px

.flag.flag-mp
  background-position: -240px -88px

.flag.flag-gt
  background-position: -80px -55px

.flag.flag-cf
  background-position: -64px -22px

.flag.flag-zanzibar
  background-position: 0 -165px

.flag.flag-mw
  background-position: -80px -99px

.flag.flag-catalonia
  background-position: -32px -22px

.flag.flag-ug
  background-position: -240px -143px

.flag.flag-je
  background-position: -176px -66px

.flag.flag-km
  background-position: -32px -77px

.flag.flag-in
  background-position: -64px -66px

.flag.flag-bf
  background-position: -48px -11px

.flag.flag-mc
  background-position: -80px -88px

.flag.flag-sy
  background-position: -192px -132px

.flag.flag-sn
  background-position: -64px -132px

.flag.flag-kr
  background-position: -80px -77px

.flag.flag-eu
  background-position: -256px -33px

.flag.flag-bn
  background-position: -144px -11px

.flag.flag-st
  background-position: -144px -132px

.flag.flag-england
  background-position: -192px -33px

.flag.flag-lc
  background-position: -192px -77px

.flag.flag-dm
  background-position: -80px -33px

.flag.flag-be
  background-position: -32px -11px

.flag.flag-ni
  background-position: -224px -99px

.flag.flag-ua
  background-position: -224px -143px

.flag.flag-mz
  background-position: -128px -99px

.flag.flag-pf
  background-position: -112px -110px

.flag.flag-tn
  background-position: -112px -143px

.flag.flag-ee
  background-position: -144px -33px

.flag.flag-xk
  background-position: -208px -154px

.flag.flag-sx
  background-position: -176px -132px

.flag.flag-sd
  background-position: -192px -121px

.flag.flag-gd
  background-position: -128px -44px

.flag.flag-ci
  background-position: -112px -22px

.flag.flag-sz
  background-position: -208px -132px

.flag.flag-cl
  background-position: -144px -22px

.flag.flag-fi
  background-position: 0 -44px

.flag.flag-ga
  background-position: -96px -44px

.flag.flag-jp
  background-position: -224px -66px

.flag.flag-de
  background-position: -32px -33px

.flag.flag-np
  background-position: 0 -110px

.flag.flag-re
  background-position: -48px -121px

.flag.flag-bg
  background-position: -64px -11px

.flag.flag-sc
  background-position: -160px -121px

.flag.flag-ng
  background-position: -208px -99px

.flag.flag-qa
  background-position: -32px -121px

.flag.flag-mk
  background-position: -160px -88px

.flag.flag-aw
  background-position: -208px 0

.flag.flag-kn
  background-position: -48px -77px

.flag.flag-al
  background-position: -80px 0

.flag.flag-bw
  background-position: -240px -11px

.flag.flag-um
  background-position: -256px -143px

.flag.flag-ky
  background-position: -128px -77px

.flag.flag-tt
  background-position: -160px -143px

.flag.flag-so
  background-position: -80px -132px

.flag.flag-lt
  background-position: 0 -88px

.flag.flag-by
  background-position: -256px -11px

.flag.flag-bb
  background-position: 0 -11px

.flag.flag-us
  background-position: 0 -154px

.flag.flag-md
  background-position: -96px -88px

.flag.flag-ag
  background-position: -48px 0

.flag.flag-hm
  background-position: -160px -55px

.flag.flag-as
  background-position: -160px 0

.flag.flag-eg
  background-position: -160px -33px

.flag.flag-sv
  background-position: -160px -132px

.flag.flag-sl
  background-position: -32px -132px

.flag.flag-fk
  background-position: -32px -44px

.flag.flag-am
  background-position: -96px 0

.flag.flag-ck
  background-position: -128px -22px

.flag.flag-tw
  background-position: -192px -143px

.flag.flag-kh
  background-position: 0 -77px

.flag.flag-to
  background-position: -128px -143px

.flag.flag-se
  background-position: -208px -121px

.flag.flag-cd
  background-position: -48px -22px

.flag.flag-pn
  background-position: -208px -110px

.flag.flag-gr
  background-position: -48px -55px

.flag.flag-id
  background-position: -256px -55px

.flag.flag-vc
  background-position: -64px -154px

.flag.flag-somaliland
  background-position: -96px -132px

.flag.flag-bi
  background-position: -96px -11px

.flag.flag-pk
  background-position: -160px -110px

.flag.flag-pr
  background-position: -224px -110px

.flag.flag-bd
  background-position: -16px -11px

.flag.flag-co
  background-position: -192px -22px

.flag.flag-fm
  background-position: -48px -44px

.flag.flag-bm
  background-position: -128px -11px

.flag.flag-ar
  background-position: -144px 0

.flag.flag-bv
  background-position: -224px -11px

.flag.flag-sb
  background-position: -144px -121px

.flag.flag-mq
  background-position: -256px -88px

.flag.flag-eh
  background-position: -176px -33px

.flag.flag-bh
  background-position: -80px -11px

.flag.flag-it
  background-position: -144px -66px

.flag.flag-hr
  background-position: -192px -55px

.flag.flag-sa
  background-position: -128px -121px

.flag.flag-mv
  background-position: -64px -99px

.flag.flag-mg
  background-position: -128px -88px

.flag.flag-dz
  background-position: -112px -33px

.flag.flag-gg
  background-position: -192px -44px

.flag.flag-gm
  background-position: -256px -44px

.flag.flag-af
  background-position: -32px 0

.flag.flag-li
  background-position: -208px -77px

.flag.flag-sr
  background-position: -112px -132px

.flag.flag-vg
  background-position: -96px -154px

.flag.flag-cr
  background-position: -208px -22px

.flag.flag-tc
  background-position: -224px -132px

.flag.flag-ao
  background-position: -128px 0

.flag.flag-ma
  background-position: -64px -88px

.flag.flag-mr
  background-position: 0 -99px

.flag.flag-gn
  background-position: 0 -55px

.flag.flag-ne
  background-position: -176px -99px

.flag.flag-nf
  background-position: -192px -99px

.flag.flag-wf
  background-position: -176px -154px

.flag.flag-hk
  background-position: -144px -55px

.flag.flag-gf
  background-position: -160px -44px

.flag.flag-ps
  background-position: -240px -110px

.flag.flag-ic
  background-position: -240px -55px

.flag.flag-cw
  background-position: -256px -22px

.flag.flag-ml
  background-position: -176px -88px

.flag.flag-ax
  background-position: -224px 0

.flag.flag-gl
  background-position: -240px -44px

.flag.flag-dj
  background-position: -48px -33px

.flag.flag-cn
  background-position: -176px -22px

.flag.flag-ht
  background-position: -208px -55px

.flag.flag-lr
  background-position: -240px -77px

.flag.flag-tg
  background-position: 0 -143px

.flag.flag-ba
  background-position: -256px 0

.flag.flag-ge
  background-position: -144px -44px

.flag.flag-bz
  background-position: 0 -22px

.flag.flag-au
  background-position: -192px 0

.flag.flag-iq
  background-position: -96px -66px

.flag.flag-cm
  background-position: -160px -22px

.flag.flag-gw
  background-position: -112px -55px

.flag.flag-az
  background-position: -240px 0

.flag.flag-na
  background-position: -144px -99px

.flag.flag-fj
  background-position: -16px -44px

.flag.flag-zw
  background-position: -32px -165px

.flag.flag-bs
  background-position: -192px -11px

.flag.flag-il
  background-position: -16px -66px

.flag.flag-nz
  background-position: -48px -110px

.flag.flag-me
  background-position: -112px -88px

.flag.flag-si
  background-position: -256px -121px

.flag.flag-nc
  background-position: -160px -99px

.flag.flag-lb
  background-position: -176px -77px

.symbol
  background-image: url("../../../public/images/symbols/symbols.e9416456.png")
  background-repeat: no-repeat
  display: block

.symbol-AA
  width: 64px
  height: 64px
  background-position: -5px -5px

.symbol-AAL
  width: 64px
  height: 64px
  background-position: -79px -5px

.symbol-AAPL
  width: 64px
  height: 64px
  background-position: -153px -5px

.symbol-AAVEUSD
  width: 64px
  height: 64px
  background-position: -227px -5px

.symbol-ABBV
  width: 64px
  height: 64px
  background-position: -301px -5px

.symbol-ABNB
  width: 64px
  height: 64px
  background-position: -375px -5px

.symbol-ACB
  width: 64px
  height: 64px
  background-position: -449px -5px

.symbol-ACRV
  width: 64px
  height: 64px
  background-position: -523px -5px

.symbol-ADAUSD
  width: 64px
  height: 64px
  background-position: -597px -5px

.symbol-ADBE
  width: 64px
  height: 64px
  background-position: -671px -5px

.symbol-ADR
  width: 60px
  height: 60px
  background-position: -745px -5px

.symbol-ADS
  width: 64px
  height: 64px
  background-position: -815px -5px

.symbol-AEM
  width: 64px
  height: 64px
  background-position: -889px -5px

.symbol-AETUF
  width: 64px
  height: 64px
  background-position: -963px -5px

.symbol-AFLT
  width: 64px
  height: 64px
  background-position: -1037px -5px

.symbol-AI
  width: 64px
  height: 64px
  background-position: -1111px -5px

.symbol-AIG
  width: 64px
  height: 64px
  background-position: -1185px -5px

.symbol-AIR
  width: 64px
  height: 64px
  background-position: -1259px -5px

.symbol-ALB
  width: 64px
  height: 64px
  background-position: -1333px -5px

.symbol-ALE
  width: 64px
  height: 64px
  background-position: -1407px -5px

.symbol-ALGOUSD
  width: 64px
  height: 64px
  background-position: -1481px -5px

.symbol-ALL
  width: 64px
  height: 64px
  background-position: -5px -79px

.symbol-ALR
  width: 64px
  height: 64px
  background-position: -79px -79px

.symbol-ALRS
  width: 60px
  height: 60px
  background-position: -153px -79px

.symbol-ALV
  width: 60px
  height: 60px
  background-position: -223px -79px

.symbol-AMD
  width: 64px
  height: 64px
  background-position: -293px -79px

.symbol-AMGN
  width: 64px
  height: 64px
  background-position: -367px -79px

.symbol-AMZN
  width: 64px
  height: 64px
  background-position: -441px -79px

.symbol-APEUSD
  width: 64px
  height: 64px
  background-position: -515px -79px

.symbol-APTUSD
  width: 64px
  height: 64px
  background-position: -589px -79px

.symbol-ARBUSD
  width: 64px
  height: 64px
  background-position: -663px -79px

.symbol-ARM
  width: 60px
  height: 60px
  background-position: -737px -79px

.symbol-ASML
  width: 64px
  height: 64px
  background-position: -807px -79px

.symbol-ASP
  width: 64px
  height: 64px
  background-position: -881px -79px

.symbol-ATAT
  width: 64px
  height: 64px
  background-position: -955px -79px

.symbol-ATMVU
  width: 64px
  height: 64px
  background-position: -1029px -79px

.symbol-ATOMUSD
  width: 64px
  height: 64px
  background-position: -1103px -79px

.symbol-ATVI
  width: 64px
  height: 64px
  background-position: -1177px -79px

.symbol-AUDCAD
  width: 64px
  height: 64px
  background-position: -1251px -79px

.symbol-AUDCHF
  width: 64px
  height: 64px
  background-position: -1325px -79px

.symbol-AUDCNY
  width: 64px
  height: 64px
  background-position: -1399px -79px

.symbol-AUDJPY
  width: 64px
  height: 64px
  background-position: -1473px -79px

.symbol-AUDNZD
  width: 64px
  height: 64px
  background-position: -5px -153px

.symbol-AUDUSD
  width: 64px
  height: 64px
  background-position: -79px -153px

.symbol-AUS200
  width: 64px
  height: 64px
  background-position: -153px -153px

.symbol-AVAXUSD
  width: 64px
  height: 64px
  background-position: -227px -153px

.symbol-AVGO
  width: 60px
  height: 60px
  background-position: -1547px -79px

.symbol-AXP
  width: 64px
  height: 64px
  background-position: -301px -153px

.symbol-AXS
  width: 64px
  height: 64px
  background-position: -375px -153px

.symbol-AXSUSD
  width: 64px
  height: 64px
  background-position: -449px -153px

.symbol-AZN
  width: 64px
  height: 64px
  background-position: -523px -153px

.symbol-BA
  width: 64px
  height: 64px
  background-position: -597px -153px

.symbol-BABA
  width: 64px
  height: 64px
  background-position: -671px -153px

.symbol-BAC
  width: 64px
  height: 64px
  background-position: -745px -153px

.symbol-BANDUSD
  width: 64px
  height: 64px
  background-position: -819px -153px

.symbol-BAS
  width: 64px
  height: 64px
  background-position: -893px -153px

.symbol-BAYN
  width: 64px
  height: 64px
  background-position: -967px -153px

.symbol-BC
  width: 64px
  height: 64px
  background-position: -1041px -153px

.symbol-BCHUSD
  width: 64px
  height: 64px
  background-position: -1115px -153px

.symbol-BE
  width: 64px
  height: 64px
  background-position: -1189px -153px

.symbol-BF-B
  width: 60px
  height: 60px
  background-position: -1547px -149px

.symbol-BIDU
  width: 64px
  height: 64px
  background-position: -1263px -153px

.symbol-BIIB
  width: 64px
  height: 64px
  background-position: -1337px -153px

.symbol-BKNG
  width: 64px
  height: 64px
  background-position: -1411px -153px

.symbol-BKSY
  width: 64px
  height: 64px
  background-position: -1485px -219px

.symbol-BLDP
  width: 64px
  height: 64px
  background-position: -5px -293px

.symbol-BLK
  width: 60px
  height: 60px
  background-position: -79px -293px

.symbol-BLURUSD
  width: 64px
  height: 64px
  background-position: -149px -293px

.symbol-BMW
  width: 64px
  height: 64px
  background-position: -223px -293px

.symbol-BNBUSD
  width: 64px
  height: 64px
  background-position: -297px -293px

.symbol-BNP
  width: 64px
  height: 64px
  background-position: -371px -293px

.symbol-BP
  width: 64px
  height: 64px
  background-position: -445px -293px

.symbol-BRK
  width: 64px
  height: 64px
  background-position: -519px -293px

.symbol-BRK-A
  width: 60px
  height: 60px
  background-position: -593px -293px

.symbol-BSVUSD
  width: 64px
  height: 64px
  background-position: -663px -293px

.symbol-BTCEUR
  width: 64px
  height: 64px
  background-position: -737px -293px

.symbol-BTCUSD
  width: 64px
  height: 64px
  background-position: -811px -293px

.symbol-BTG
  width: 64px
  height: 64px
  background-position: -885px -293px

.symbol-BTI
  width: 64px
  height: 64px
  background-position: -959px -293px

.symbol-BTTUSD
  width: 64px
  height: 64px
  background-position: -1033px -293px

.symbol-C
  background-position: -1107px -293px

.symbol-C,.symbol-CAC
  width: 64px
  height: 64px

.symbol-CAC
  background-position: -1181px -293px

.symbol-CADCHF
  width: 64px
  height: 64px
  background-position: -1255px -293px

.symbol-CADJPY
  width: 64px
  height: 64px
  background-position: -1329px -293px

.symbol-CAKEUSD
  width: 64px
  height: 64px
  background-position: -1403px -293px

.symbol-CAPITALSUGAR
  width: 60px
  height: 60px
  background-position: -1477px -293px

.symbol-CAT
  width: 64px
  height: 64px
  background-position: -1477px -363px

.symbol-CBK
  width: 64px
  height: 64px
  background-position: -5px -437px

.symbol-CCL
  width: 64px
  height: 64px
  background-position: -79px -437px

.symbol-CDR
  width: 64px
  height: 64px
  background-position: -153px -437px

.symbol-CFXUSD
  width: 64px
  height: 64px
  background-position: -227px -437px

.symbol-CGC
  width: 64px
  height: 64px
  background-position: -301px -437px

.symbol-CHFJPY
  width: 64px
  height: 64px
  background-position: -375px -437px

.symbol-CHL
  width: 64px
  height: 64px
  background-position: -449px -437px

.symbol-CHPT
  width: 64px
  height: 64px
  background-position: -523px -437px

.symbol-CHV
  width: 64px
  height: 64px
  background-position: -597px -437px

.symbol-CHZUSD
  width: 64px
  height: 64px
  background-position: -671px -437px

.symbol-CM
  width: 64px
  height: 64px
  background-position: -745px -437px

.symbol-CNQ
  width: 64px
  height: 64px
  background-position: -819px -437px

.symbol-COCOA
  width: 64px
  height: 64px
  background-position: -893px -437px

.symbol-COFFEE
  width: 64px
  height: 64px
  background-position: -967px -437px

.symbol-COIN
  width: 60px
  height: 60px
  background-position: -1547px -293px

.symbol-COTTON
  width: 64px
  height: 64px
  background-position: -1041px -437px

.symbol-CQQQ
  width: 64px
  height: 64px
  background-position: -1115px -437px

.symbol-CRM
  width: 64px
  height: 64px
  background-position: -1189px -437px

.symbol-CSCO
  width: 64px
  height: 64px
  background-position: -1263px -437px

.symbol-CVE
  width: 64px
  height: 64px
  background-position: -1337px -437px

.symbol-CVS
  width: 60px
  height: 60px
  background-position: -79px -363px

.symbol-DAI
  width: 60px
  height: 60px
  background-position: -593px -363px

.symbol-DAL
  width: 64px
  height: 64px
  background-position: -1411px -437px

.symbol-DAN
  width: 64px
  height: 64px
  background-position: -1485px -437px

.symbol-DASHUSD
  width: 64px
  height: 64px
  background-position: -5px -511px

.symbol-DAX
  width: 64px
  height: 64px
  background-position: -79px -511px

.symbol-DBK
  width: 64px
  height: 64px
  background-position: -153px -511px

.symbol-DBX
  width: 64px
  height: 64px
  background-position: -227px -511px

.symbol-DELL
  width: 60px
  height: 60px
  background-position: -301px -511px

.symbol-DG
  width: 64px
  height: 64px
  background-position: -371px -511px

.symbol-DIS
  width: 64px
  height: 64px
  background-position: -445px -511px

.symbol-DJX
  width: 64px
  height: 64px
  background-position: -519px -511px

.symbol-DOGEUSD
  width: 64px
  height: 64px
  background-position: -593px -511px

.symbol-DOTUSD
  width: 64px
  height: 64px
  background-position: -667px -511px

.symbol-DPZ
  width: 64px
  height: 64px
  background-position: -741px -511px

.symbol-DSHUSD
  width: 64px
  height: 64px
  background-position: -815px -511px

.symbol-DWAC
  width: 64px
  height: 64px
  background-position: -889px -511px

.symbol-DXY
  width: 64px
  height: 64px
  background-position: -963px -511px

.symbol-DYMUSD
  width: 64px
  height: 64px
  background-position: -1037px -511px

.symbol-EBAY
  width: 64px
  height: 64px
  background-position: -1111px -511px

.symbol-EDR
  width: 64px
  height: 64px
  background-position: -1185px -511px

.symbol-EGLDUSD
  width: 64px
  height: 64px
  background-position: -1259px -511px

.symbol-EKRNUSD
  width: 64px
  height: 64px
  background-position: -1333px -511px

.symbol-EKRONA
  width: 64px
  height: 64px
  background-position: -1407px -511px

.symbol-EL
  width: 64px
  height: 64px
  background-position: -1481px -511px

.symbol-EMBK
  width: 64px
  height: 64px
  background-position: -5px -585px

.symbol-ENB
  width: 64px
  height: 64px
  background-position: -79px -585px

.symbol-ENI
  width: 64px
  height: 64px
  background-position: -153px -585px

.symbol-EOSUSD
  width: 64px
  height: 64px
  background-position: -227px -585px

.symbol-ERBB
  width: 64px
  height: 64px
  background-position: -301px -585px

.symbol-ERDUSD
  width: 64px
  height: 64px
  background-position: -375px -585px

.symbol-ESLT
  width: 64px
  height: 64px
  background-position: -449px -585px

.symbol-ETCUSD
  width: 64px
  height: 64px
  background-position: -523px -585px

.symbol-ETHUSD
  width: 64px
  height: 64px
  background-position: -597px -585px

.symbol-EURAUD
  width: 64px
  height: 64px
  background-position: -671px -585px

.symbol-EURCAD
  width: 64px
  height: 64px
  background-position: -745px -585px

.symbol-EURCHF
  width: 64px
  height: 64px
  background-position: -819px -585px

.symbol-EURCNY
  width: 64px
  height: 64px
  background-position: -893px -585px

.symbol-EURGBP
  width: 64px
  height: 64px
  background-position: -967px -585px

.symbol-EURJPY
  width: 64px
  height: 64px
  background-position: -1041px -585px

.symbol-EURNZD
  width: 64px
  height: 64px
  background-position: -1115px -585px

.symbol-EURRUB
  width: 64px
  height: 64px
  background-position: -1189px -585px

.symbol-EURSGD
  width: 64px
  height: 64px
  background-position: -1263px -585px

.symbol-EURUSD
  width: 64px
  height: 64px
  background-position: -1337px -585px

.symbol-EUSTX50
  width: 64px
  height: 64px
  background-position: -1411px -585px

.symbol-F
  background-position: -1485px -585px

.symbol-F,.symbol-FB
  width: 64px
  height: 64px

.symbol-FB
  background-position: -5px -659px

.symbol-FDX
  width: 64px
  height: 64px
  background-position: -79px -659px

.symbol-FDXTF
  width: 64px
  height: 64px
  background-position: -153px -659px

.symbol-FILUSD
  width: 64px
  height: 64px
  background-position: -227px -659px

.symbol-FIT
  width: 64px
  height: 64px
  background-position: -301px -659px

.symbol-FLOWUSD
  width: 64px
  height: 64px
  background-position: -375px -659px

.symbol-FP
  width: 64px
  height: 64px
  background-position: -449px -659px

.symbol-FQVLF
  width: 64px
  height: 64px
  background-position: -523px -659px

.symbol-FTMUSD
  width: 64px
  height: 64px
  background-position: -597px -659px

.symbol-FTSE
  width: 64px
  height: 64px
  background-position: -671px -659px

.symbol-FTT
  width: 64px
  height: 64px
  background-position: -745px -659px

.symbol-FTTUSD
  width: 64px
  height: 64px
  background-position: -819px -659px

.symbol-GALAUSD
  width: 64px
  height: 64px
  background-position: -893px -659px

.symbol-GAS
  width: 64px
  height: 64px
  background-position: -967px -659px

.symbol-GAZP
  width: 64px
  height: 64px
  background-position: -1041px -659px

.symbol-GBNXF
  width: 64px
  height: 64px
  background-position: -1115px -659px

.symbol-GBPAUD
  width: 64px
  height: 64px
  background-position: -1189px -659px

.symbol-GBPCAD
  width: 64px
  height: 64px
  background-position: -1263px -659px

.symbol-GBPCHF
  width: 64px
  height: 64px
  background-position: -1337px -659px

.symbol-GBPJPY
  width: 64px
  height: 64px
  background-position: -1411px -659px

.symbol-GBPNZD
  width: 64px
  height: 64px
  background-position: -1485px -659px

.symbol-GBPUSD
  width: 64px
  height: 64px
  background-position: -5px -733px

.symbol-GBTC
  width: 64px
  height: 64px
  background-position: -79px -733px

.symbol-GE
  width: 64px
  height: 64px
  background-position: -153px -733px

.symbol-GENGF
  width: 64px
  height: 64px
  background-position: -227px -733px

.symbol-GILD
  width: 64px
  height: 64px
  background-position: -301px -733px

.symbol-GM
  width: 64px
  height: 64px
  background-position: -375px -733px

.symbol-GMKN
  width: 64px
  height: 64px
  background-position: -449px -733px

.symbol-GNLX
  width: 64px
  height: 64px
  background-position: -523px -733px

.symbol-GOLD
  width: 64px
  height: 64px
  background-position: -597px -733px

.symbol-GOOGL
  width: 64px
  height: 64px
  background-position: -671px -733px

.symbol-GOOGLE
  width: 64px
  height: 64px
  background-position: -745px -733px

.symbol-GPRO
  width: 64px
  height: 64px
  background-position: -819px -733px

.symbol-GRTUSD
  width: 64px
  height: 64px
  background-position: -893px -733px

.symbol-GS
  width: 64px
  height: 64px
  background-position: -967px -733px

.symbol-HBARUSD
  width: 64px
  height: 64px
  background-position: -1041px -733px

.symbol-HD
  background-position: -1115px -733px

.symbol-HD,.symbol-HG
  width: 64px
  height: 64px

.symbol-HG
  background-position: -1189px -733px

.symbol-HOG
  width: 64px
  height: 64px
  background-position: -1263px -733px

.symbol-HPQ
  width: 64px
  height: 64px
  background-position: -1337px -733px

.symbol-HSI
  width: 64px
  height: 64px
  background-position: -1411px -733px

.symbol-HTOO
  width: 64px
  height: 64px
  background-position: -1485px -733px

.symbol-IBEX
  width: 64px
  height: 64px
  background-position: -5px -807px

.symbol-IBIT
  width: 64px
  height: 64px
  background-position: -79px -807px

.symbol-IBM
  width: 64px
  height: 64px
  background-position: -153px -807px

.symbol-ICPUSD
  width: 64px
  height: 64px
  background-position: -227px -807px

.symbol-IDUSD
  width: 64px
  height: 64px
  background-position: -301px -807px

.symbol-IMO
  width: 64px
  height: 65px
  background-position: -375px -807px

.symbol-INJUSD
  width: 64px
  height: 64px
  background-position: -449px -807px

.symbol-INO
  width: 64px
  height: 64px
  background-position: -523px -807px

.symbol-INTC
  width: 64px
  height: 64px
  background-position: -597px -807px

.symbol-IOTUSD
  width: 64px
  height: 64px
  background-position: -671px -807px

.symbol-IRTS
  width: 64px
  height: 64px
  background-position: -745px -807px

.symbol-ITX
  width: 64px
  height: 64px
  background-position: -819px -807px

.symbol-IXIC
  width: 64px
  height: 64px
  background-position: -893px -807px

.symbol-JNJ
  width: 64px
  height: 64px
  background-position: -967px -807px

.symbol-JPM
  width: 64px
  height: 64px
  background-position: -1041px -807px

.symbol-JSW
  width: 64px
  height: 64px
  background-position: -1115px -807px

.symbol-JTOUSD
  width: 64px
  height: 64px
  background-position: -1189px -807px

.symbol-JUVE
  width: 64px
  height: 64px
  background-position: -1263px -807px

.symbol-KAVAUSD
  width: 64px
  height: 64px
  background-position: -1337px -807px

.symbol-KGC
  width: 64px
  height: 64px
  background-position: -1411px -807px

.symbol-KGHA
  width: 64px
  height: 64px
  background-position: -1485px -807px

.symbol-KLAYUSD
  width: 64px
  height: 64px
  background-position: -5px -881px

.symbol-KMX
  width: 64px
  height: 64px
  background-position: -79px -881px

.symbol-KO
  width: 64px
  height: 64px
  background-position: -153px -881px

.symbol-KSCP
  width: 64px
  height: 64px
  background-position: -227px -881px

.symbol-LAC
  width: 64px
  height: 64px
  background-position: -301px -881px

.symbol-LAZR
  width: 64px
  height: 65px
  background-position: -449px -881px

.symbol-LCID
  width: 60px
  height: 60px
  background-position: -523px -881px

.symbol-LDOUSD
  width: 64px
  height: 64px
  background-position: -593px -881px

.symbol-LENDUSD
  width: 64px
  height: 64px
  background-position: -667px -881px

.symbol-LHA
  width: 64px
  height: 64px
  background-position: -741px -881px

.symbol-LI
  width: 60px
  height: 60px
  background-position: -815px -881px

.symbol-LIC
  width: 60px
  height: 60px
  background-position: -885px -881px

.symbol-LINKUSD
  width: 64px
  height: 64px
  background-position: -955px -881px

.symbol-LIT
  width: 64px
  height: 64px
  background-position: -1029px -881px

.symbol-LKOH
  width: 64px
  height: 64px
  background-position: -1103px -881px

.symbol-LMT
  width: 64px
  height: 64px
  background-position: -1177px -881px

.symbol-LTCUSD
  width: 64px
  height: 64px
  background-position: -1251px -881px

.symbol-LTS
  width: 64px
  height: 64px
  background-position: -1325px -881px

.symbol-LULU
  width: 64px
  height: 64px
  background-position: -1399px -881px

.symbol-LUNAUSD
  width: 64px
  height: 64px
  background-position: -1473px -881px

.symbol-LUNCUSD
  width: 64px
  height: 64px
  background-position: -5px -955px

.symbol-LUNMF
  width: 64px
  height: 64px
  background-position: -79px -955px

.symbol-LVMH
  width: 64px
  height: 64px
  background-position: -153px -955px

.symbol-LVMHF
  width: 64px
  height: 64px
  background-position: -227px -955px

.symbol-LYFT
  width: 64px
  height: 64px
  background-position: -301px -955px

.symbol-LYG
  width: 64px
  height: 64px
  background-position: -375px -955px

.symbol-MA
  width: 64px
  height: 64px
  background-position: -523px -955px

.symbol-MAHMF
  width: 64px
  height: 64px
  background-position: -597px -955px

.symbol-MANAUSD
  width: 64px
  height: 64px
  background-position: -671px -955px

.symbol-MANTAUSD
  width: 64px
  height: 64px
  background-position: -745px -955px

.symbol-MANU
  width: 64px
  height: 65px
  background-position: -819px -955px

.symbol-MARXU
  width: 64px
  height: 64px
  background-position: -893px -955px

.symbol-MATICUSD
  width: 64px
  height: 64px
  background-position: -967px -955px

.symbol-MBK
  width: 64px
  height: 64px
  background-position: -1041px -955px

.symbol-MCD
  width: 64px
  height: 64px
  background-position: -1115px -955px

.symbol-MDT
  width: 64px
  height: 64px
  background-position: -1189px -955px

.symbol-MEGEF
  width: 64px
  height: 64px
  background-position: -1263px -955px

.symbol-MGA
  width: 64px
  height: 64px
  background-position: -1337px -955px

.symbol-MGM
  width: 64px
  height: 64px
  background-position: -1411px -955px

.symbol-MGPRF
  width: 64px
  height: 64px
  background-position: -1485px -955px

.symbol-MIB
  width: 64px
  height: 64px
  background-position: -5px -1029px

.symbol-MKRUSD
  width: 64px
  height: 64px
  background-position: -79px -1029px

.symbol-MMM
  width: 64px
  height: 64px
  background-position: -153px -1029px

.symbol-MO
  width: 64px
  height: 64px
  background-position: -227px -1029px

.symbol-MRK
  width: 60px
  height: 60px
  background-position: -1547px -881px

.symbol-MRNA
  width: 64px
  height: 64px
  background-position: -301px -1029px

.symbol-MRVL
  width: 60px
  height: 60px
  background-position: -375px -1029px

.symbol-MS
  width: 64px
  height: 64px
  background-position: -445px -1029px

.symbol-MSFT
  width: 64px
  height: 64px
  background-position: -519px -1029px

.symbol-MSTR
  width: 64px
  height: 64px
  background-position: -593px -1029px

.symbol-MTS
  width: 64px
  height: 64px
  background-position: -667px -1029px

.symbol-MU
  width: 64px
  height: 65px
  background-position: -741px -1029px

.symbol-NDX
  width: 64px
  height: 64px
  background-position: -893px -1029px

.symbol-NEARUSD
  width: 64px
  height: 64px
  background-position: -967px -1029px

.symbol-NEM
  width: 64px
  height: 64px
  background-position: -1041px -1029px

.symbol-NEOUSD
  width: 64px
  height: 64px
  background-position: -1115px -1029px

.symbol-NET
  width: 60px
  height: 60px
  background-position: -1189px -1029px

.symbol-NFLX
  width: 64px
  height: 64px
  background-position: -1259px -1029px

.symbol-NFTBACC
  width: 64px
  height: 64px
  background-position: -1333px -1029px

.symbol-NFTBAKC
  width: 64px
  height: 64px
  background-position: -1407px -1029px

.symbol-NFTCC
  width: 64px
  height: 64px
  background-position: -1481px -1029px

.symbol-NFTCK
  width: 64px
  height: 64px
  background-position: -5px -1103px

.symbol-NFTCKVX
  width: 64px
  height: 64px
  background-position: -79px -1103px

.symbol-NFTCP
  width: 64px
  height: 64px
  background-position: -153px -1103px

.symbol-NFTDF
  width: 64px
  height: 64px
  background-position: -227px -1103px

.symbol-NFTEV
  width: 64px
  height: 64px
  background-position: -301px -1103px

.symbol-NFTFLUF
  width: 64px
  height: 64px
  background-position: -375px -1103px

.symbol-NFTFRWC
  width: 64px
  height: 64px
  background-position: -449px -1103px

.symbol-NFTLL
  width: 64px
  height: 64px
  background-position: -523px -1103px

.symbol-NFTMAYC
  width: 64px
  height: 64px
  background-position: -597px -1103px

.symbol-NFTMB
  width: 64px
  height: 64px
  background-position: -815px -1103px

.symbol-NFTPA
  width: 64px
  height: 64px
  background-position: -889px -1103px

.symbol-NFTPC
  width: 64px
  height: 64px
  background-position: -963px -1103px

.symbol-NFTPP
  width: 64px
  height: 64px
  background-position: -1037px -1103px

.symbol-NFTSB
  width: 64px
  height: 64px
  background-position: -1111px -1103px

.symbol-NFTSR
  width: 64px
  height: 64px
  background-position: -1185px -1103px

.symbol-NFTVF
  width: 64px
  height: 64px
  background-position: -1259px -1103px

.symbol-NFTWOW
  width: 64px
  height: 64px
  background-position: -1333px -1103px

.symbol-NGAS
  width: 64px
  height: 64px
  background-position: -1407px -1103px

.symbol-NI225
  width: 64px
  height: 64px
  background-position: -1481px -1103px

.symbol-NIFTY
  width: 64px
  height: 64px
  background-position: -5px -1177px

.symbol-NIO
  width: 64px
  height: 64px
  background-position: -79px -1177px

.symbol-NKE
  width: 64px
  height: 64px
  background-position: -153px -1177px

.symbol-NOC
  width: 64px
  height: 64px
  background-position: -227px -1177px

.symbol-NOKIA
  width: 64px
  height: 64px
  background-position: -301px -1177px

.symbol-NQROBOUSN
  width: 64px
  height: 64px
  background-position: -375px -1177px

.symbol-NTR
  width: 64px
  height: 64px
  background-position: -449px -1177px

.symbol-NVDA
  width: 64px
  height: 64px
  background-position: -523px -1177px

.symbol-NVTK
  width: 64px
  height: 64px
  background-position: -597px -1177px

.symbol-NYSE
  width: 64px
  height: 64px
  background-position: -671px -1177px

.symbol-NZDAUD
  width: 64px
  height: 64px
  background-position: -745px -1177px

.symbol-NZDCAD
  width: 64px
  height: 64px
  background-position: -819px -1177px

.symbol-NZDCHF
  width: 64px
  height: 64px
  background-position: -893px -1177px

.symbol-NZDJPY
  width: 64px
  height: 64px
  background-position: -967px -1177px

.symbol-NZDUSD
  width: 64px
  height: 64px
  background-position: -1041px -1177px

.symbol-OMXS30
  width: 64px
  height: 64px
  background-position: -1115px -1177px

.symbol-ONTUSD
  width: 64px
  height: 64px
  background-position: -1189px -1177px

.symbol-OOP
  width: 64px
  height: 64px
  background-position: -1263px -1177px

.symbol-OPUSD
  width: 64px
  height: 64px
  background-position: -1337px -1177px

.symbol-ORCL
  width: 64px
  height: 64px
  background-position: -1411px -1177px

.symbol-ORDIUSD
  width: 64px
  height: 64px
  background-position: -1485px -1177px

.symbol-PA
  width: 64px
  height: 64px
  background-position: -5px -1251px

.symbol-PARXF
  width: 64px
  height: 64px
  background-position: -79px -1251px

.symbol-PAXGUSD
  width: 64px
  height: 64px
  background-position: -153px -1251px

.symbol-PBA
  width: 64px
  height: 64px
  background-position: -227px -1251px

.symbol-PENDLEUSD
  width: 64px
  height: 64px
  background-position: -301px -1251px

.symbol-PEO
  width: 64px
  height: 64px
  background-position: -375px -1251px

.symbol-PEP
  width: 64px
  height: 64px
  background-position: -449px -1251px

.symbol-PEPEUSD
  width: 64px
  height: 64px
  background-position: -523px -1251px

.symbol-PFE
  width: 64px
  height: 64px
  background-position: -597px -1251px

.symbol-PG
  width: 64px
  height: 64px
  background-position: -671px -1251px

.symbol-PGE
  width: 64px
  height: 64px
  background-position: -745px -1251px

.symbol-PHIA
  width: 64px
  height: 64px
  background-position: -819px -1251px

.symbol-PINS
  width: 64px
  height: 64px
  background-position: -893px -1251px

.symbol-PKIUF
  width: 64px
  height: 64px
  background-position: -967px -1251px

.symbol-PKN
  width: 64px
  height: 64px
  background-position: -1041px -1251px

.symbol-PKO
  width: 64px
  height: 64px
  background-position: -1115px -1251px

.symbol-PL
  width: 64px
  height: 64px
  background-position: -1189px -1251px

.symbol-PLL
  width: 64px
  height: 64px
  background-position: -1263px -1251px

.symbol-PLTR
  width: 60px
  height: 60px
  background-position: -671px -1103px

.symbol-PLUG
  width: 64px
  height: 64px
  background-position: -1337px -1251px

.symbol-PM
  width: 64px
  height: 64px
  background-position: -1411px -1251px

.symbol-PSKOF
  width: 64px
  height: 64px
  background-position: -1485px -1251px

.symbol-PSKOF_2
  width: 64px
  height: 64px
  background-position: -5px -1325px

.symbol-PTALF
  width: 64px
  height: 64px
  background-position: -79px -1325px

.symbol-PTR
  width: 64px
  height: 64px
  background-position: -153px -1325px

.symbol-PVH
  width: 60px
  height: 60px
  background-position: -227px -1325px

.symbol-PYPL
  width: 64px
  height: 64px
  background-position: -297px -1325px

.symbol-QCOM
  width: 64px
  height: 64px
  background-position: -371px -1325px

.symbol-QNTUSD
  width: 64px
  height: 64px
  background-position: -445px -1325px

.symbol-QQQ
  width: 64px
  height: 64px
  background-position: -519px -1325px

.symbol-QS
  width: 60px
  height: 60px
  background-position: -593px -1325px

.symbol-QTUMUSD
  width: 64px
  height: 64px
  background-position: -663px -1325px

.symbol-RACE
  width: 64px
  height: 64px
  background-position: -737px -1325px

.symbol-RBC
  width: 64px
  height: 64px
  background-position: -811px -1325px

.symbol-RCL
  width: 64px
  height: 64px
  background-position: -885px -1325px

.symbol-REPYF
  width: 64px
  height: 64px
  background-position: -959px -1325px

.symbol-REPYY
  width: 64px
  height: 64px
  background-position: -1033px -1325px

.symbol-RHHBY
  width: 60px
  height: 60px
  background-position: -1107px -1325px

.symbol-RIFUSD
  width: 64px
  height: 64px
  background-position: -1177px -1325px

.symbol-RIO
  width: 60px
  height: 60px
  background-position: -1251px -1325px

.symbol-ROSN
  width: 64px
  height: 64px
  background-position: -1321px -1325px

.symbol-RTSI
  width: 64px
  height: 64px
  background-position: -1395px -1325px

.symbol-RTX
  width: 60px
  height: 60px
  background-position: -1469px -1325px

.symbol-RUNEUSD
  width: 64px
  height: 64px
  background-position: -1539px -1325px

.symbol-SANDUSD
  width: 64px
  height: 64px
  background-position: -5px -1399px

.symbol-SAP
  width: 64px
  height: 64px
  background-position: -79px -1399px

.symbol-SBER
  width: 64px
  height: 64px
  background-position: -153px -1399px

.symbol-SBUX
  width: 64px
  height: 64px
  background-position: -227px -1399px

.symbol-SCCO
  width: 60px
  height: 60px
  background-position: -301px -1399px

.symbol-SEIUSD
  width: 64px
  height: 64px
  background-position: -371px -1399px

.symbol-SGML
  width: 64px
  height: 64px
  background-position: -445px -1399px

.symbol-SHC
  width: 64px
  height: 64px
  background-position: -519px -1399px

.symbol-SHEL
  width: 64px
  height: 64px
  background-position: -593px -1399px

.symbol-SHIBUSD
  width: 64px
  height: 64px
  background-position: -667px -1399px

.symbol-SIE
  width: 64px
  height: 64px
  background-position: -741px -1399px

.symbol-SITM
  width: 60px
  height: 60px
  background-position: -815px -1399px

.symbol-SJM
  width: 60px
  height: 60px
  background-position: -885px -1399px

.symbol-SLI
  width: 64px
  height: 64px
  background-position: -955px -1399px

.symbol-SMCI
  width: 64px
  height: 64px
  background-position: -1029px -1399px

.symbol-SMI
  width: 64px
  height: 64px
  background-position: -1103px -1399px

.symbol-SNAL
  width: 64px
  height: 64px
  background-position: -1177px -1399px

.symbol-SNAP
  width: 64px
  height: 64px
  background-position: -1251px -1399px

.symbol-SNXUSD
  width: 64px
  height: 64px
  background-position: -1325px -1399px

.symbol-SOLUSD
  width: 64px
  height: 64px
  background-position: -1399px -1399px

.symbol-SONY
  width: 64px
  height: 64px
  background-position: -1473px -1399px

.symbol-SOUN
  width: 60px
  height: 60px
  background-position: -1547px -1399px

.symbol-SOYBEAN
  width: 64px
  height: 64px
  background-position: -5px -1473px

.symbol-SPL
  width: 64px
  height: 64px
  background-position: -79px -1473px

.symbol-SPOT
  width: 64px
  height: 64px
  background-position: -153px -1473px

.symbol-SPR
  width: 64px
  height: 64px
  background-position: -227px -1473px

.symbol-SPX
  width: 64px
  height: 64px
  background-position: -301px -1473px

.symbol-SQ
  width: 64px
  height: 64px
  background-position: -375px -1473px

.symbol-SQM
  width: 64px
  height: 64px
  background-position: -449px -1473px

.symbol-SQQQ
  width: 64px
  height: 64px
  background-position: -523px -1473px

.symbol-STOXX
  width: 64px
  height: 64px
  background-position: -597px -1473px

.symbol-STXUSD
  width: 64px
  height: 64px
  background-position: -671px -1473px

.symbol-SU
  width: 64px
  height: 64px
  background-position: -745px -1473px

.symbol-SUGAR
  width: 64px
  height: 64px
  background-position: -819px -1473px

.symbol-SUIUSD
  width: 64px
  height: 64px
  background-position: -893px -1473px

.symbol-SWKS
  width: 60px
  height: 60px
  background-position: -1547px -1469px

.symbol-SXPUSD
  width: 64px
  height: 64px
  background-position: -967px -1473px

.symbol-SYM
  background-position: -1041px -1473px

.symbol-SYM,.symbol-T
  width: 64px
  height: 64px

.symbol-T
  background-position: -1115px -1473px

.symbol-TCS
  width: 64px
  height: 64px
  background-position: -1189px -1473px

.symbol-TD
  width: 64px
  height: 64px
  background-position: -1263px -1473px

.symbol-TECK
  width: 64px
  height: 64px
  background-position: -1337px -1473px

.symbol-TEVA
  width: 64px
  height: 64px
  background-position: -1411px -1473px

.symbol-THETAUSD
  width: 64px
  height: 64px
  background-position: -1485px -1539px

.symbol-TIF
  width: 64px
  height: 64px
  background-position: -1555px -5px

.symbol-TLRY
  width: 64px
  height: 64px
  background-position: -1617px -79px

.symbol-TLS
  width: 64px
  height: 64px
  background-position: -1617px -153px

.symbol-TM
  width: 64px
  height: 64px
  background-position: -1617px -227px

.symbol-TPE
  width: 64px
  height: 64px
  background-position: -1617px -301px

.symbol-TPG
  width: 64px
  height: 64px
  background-position: -1559px -375px

.symbol-TQQQ
  width: 64px
  height: 64px
  background-position: -1559px -449px

.symbol-TRIP
  width: 64px
  height: 64px
  background-position: -1559px -523px

.symbol-TRP
  width: 64px
  height: 64px
  background-position: -1559px -597px

.symbol-TRV
  width: 64px
  height: 64px
  background-position: -1559px -671px

.symbol-TRXUSD
  width: 64px
  height: 64px
  background-position: -1559px -745px

.symbol-TSLA
  width: 64px
  height: 64px
  background-position: -1617px -819px

.symbol-TSM
  width: 64px
  height: 64px
  background-position: -1617px -893px

.symbol-TSN
  width: 64px
  height: 64px
  background-position: -1559px -967px

.symbol-TWTR
  width: 64px
  height: 64px
  background-position: -1555px -1041px

.symbol-TWTUSD
  width: 64px
  height: 64px
  background-position: -1559px -1115px

.symbol-TXCX
  width: 64px
  height: 64px
  background-position: -1559px -1189px

.symbol-UBER
  width: 64px
  height: 64px
  background-position: -1613px -1263px

.symbol-UKOIL
  width: 64px
  height: 64px
  background-position: -1617px -1337px

.symbol-UNA
  width: 64px
  height: 64px
  background-position: -1617px -1411px

.symbol-UNH
  width: 64px
  height: 64px
  background-position: -1617px -1485px

.symbol-UNIUSD
  width: 64px
  height: 64px
  background-position: -5px -1559px

.symbol-USDCAD
  width: 64px
  height: 64px
  background-position: -79px -1559px

.symbol-USDCHF
  width: 64px
  height: 64px
  background-position: -153px -1559px

.symbol-USDCNY
  width: 64px
  height: 64px
  background-position: -227px -1559px

.symbol-USDHKD
  width: 64px
  height: 64px
  background-position: -301px -1559px

.symbol-USDILS
  width: 64px
  height: 64px
  background-position: -375px -1559px

.symbol-USDINR
  width: 64px
  height: 64px
  background-position: -449px -1559px

.symbol-USDJPY
  width: 64px
  height: 64px
  background-position: -523px -1559px

.symbol-USDMXN
  width: 64px
  height: 64px
  background-position: -597px -1559px

.symbol-USDPLN
  width: 64px
  height: 64px
  background-position: -671px -1559px

.symbol-USDRUB
  width: 64px
  height: 64px
  background-position: -745px -1559px

.symbol-USDSGD
  width: 64px
  height: 64px
  background-position: -819px -1559px

.symbol-USDTRY
  width: 64px
  height: 64px
  background-position: -893px -1559px

.symbol-USDZAR
  width: 64px
  height: 64px
  background-position: -967px -1559px

.symbol-USOIL
  width: 64px
  height: 64px
  background-position: -1041px -1559px

.symbol-V
  width: 64px
  height: 64px
  background-position: -1115px -1559px

.symbol-VALE
  width: 64px
  height: 64px
  background-position: -1189px -1559px

.symbol-VETUSD
  width: 64px
  height: 64px
  background-position: -1263px -1559px

.symbol-VFC
  width: 64px
  height: 64px
  background-position: -1337px -1559px

.symbol-VIX
  width: 64px
  height: 64px
  background-position: -1411px -1559px

.symbol-VOD
  width: 64px
  height: 64px
  background-position: -1559px -1559px

.symbol-VOW
  width: 64px
  height: 64px
  background-position: -1629px -5px

.symbol-VSCO
  width: 64px
  height: 64px
  background-position: -1691px -79px

.symbol-VTB
  width: 60px
  height: 60px
  background-position: -1691px -153px

.symbol-VZ
  width: 64px
  height: 64px
  background-position: -1691px -223px

.symbol-WAVESUSD
  width: 64px
  height: 64px
  background-position: -1691px -297px

.symbol-WBD
  width: 64px
  height: 64px
  background-position: -1691px -371px

.symbol-WBX
  width: 64px
  height: 64px
  background-position: -1633px -445px

.symbol-WFC
  width: 64px
  height: 64px
  background-position: -1633px -519px

.symbol-WHEAT
  width: 64px
  height: 64px
  background-position: -1633px -593px

.symbol-WLDUSD
  width: 64px
  height: 64px
  background-position: -1633px -667px

.symbol-WMT
  width: 64px
  height: 64px
  background-position: -1633px -741px

.symbol-WORK
  width: 64px
  height: 64px
  background-position: -1691px -815px

.symbol-WPRT
  width: 64px
  height: 64px
  background-position: -1691px -889px

.symbol-WYNN
  width: 64px
  height: 64px
  background-position: -1691px -963px

.symbol-XAGUSD
  width: 64px
  height: 64px
  background-position: -1633px -1037px

.symbol-XAUUSD
  width: 64px
  height: 64px
  background-position: -1633px -1111px

.symbol-XECUSD
  width: 64px
  height: 64px
  background-position: -1633px -1185px

.symbol-XLMUSD
  width: 64px
  height: 64px
  background-position: -1687px -1259px

.symbol-XMRUSD
  width: 64px
  height: 64px
  background-position: -1691px -1333px

.symbol-XOM
  width: 64px
  height: 64px
  background-position: -1691px -1407px

.symbol-XRPEUR
  width: 64px
  height: 64px
  background-position: -1691px -1481px

.symbol-XRPUSD
  width: 64px
  height: 64px
  background-position: -1691px -1555px

.symbol-XTZUSD
  width: 64px
  height: 64px
  background-position: -1485px -1629px

.symbol-YCCUSD
  width: 64px
  height: 64px
  background-position: -1633px -1629px

.symbol-YNDX
  width: 64px
  height: 64px
  background-position: -1703px -5px

.symbol-YNPUSD
  width: 64px
  height: 64px
  background-position: -1765px -79px

.symbol-YUANC
  width: 64px
  height: 64px
  background-position: -1765px -153px

.symbol-YUANPAY
  width: 64px
  height: 64px
  background-position: -1765px -227px

.symbol-ZECUSD
  width: 64px
  height: 64px
  background-position: -1765px -301px

.symbol-ZM
  width: 64px
  height: 64px
  background-position: -1765px -375px

.symbol-ZNGA
  width: 64px
  height: 64px
  background-position: -1707px -449px
