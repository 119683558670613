// common styles
*::-webkit-scrollbar
    height: 10px
    width: 10px
*::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #fff
*::-webkit-scrollbar-thumb
    border-radius: 9px
    background-color: #d1c7c7


body
    min-width: 375px
    background: $n8
    +poppins
    font-size: 14px
    line-height: (24/14)
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: $n2
    +dark-body
        background: $n1
        color: $n8
        * ::-webkit-scrollbar-thumb
            background-color: #000
        * ::-webkit-scrollbar-track
            background-color: #141416
            &:hover
                background-color: #37393A
            &:active
                background-color: #2F3131

a
    text-decoration: none

svg,
img
    vertical-align: middle

.desktop
    &-hide
        +d
            display: none !important
    &-show
        display: none !important
        +d
            display: block !important
    &-text-right
        +d
            text-align: right

.tablet
    &-hide
        +t
            display: none !important
    &-show
        display: none !important
        +t
            display: block !important

.mobile
    &-hide
        +m
            display: none !important
    &-show
        display: none !important
        +m
            display: block !important

#webpack-dev-server-client-overlay
    display: none
