@import '../../../styles/helpers'

.autotrader
  padding: 20px

.title
  margin-bottom: 32px
  padding-right: 56px
  font-size: 20px

  +m
    +poppins
    font-size: 20px
    line-height: 32px
    font-weight: 600
  svg
    position: relative
    top: -2px
    margin-right: 16px
    +m
      top: 0
    +dark
      fill: $n8

.field
  margin-top: 10px
  display: flex
  justify-content: space-between
  align-items: center

.radioGroup
  display: flex
  flex-direction: column

.radio
  margin-top: 10px

.footer
  display: flex
  justify-content: flex-end
  margin-top: 20px

.cancelButton
  margin-right: 10px

.saveButton
  button
.dropdownList
  width: 100px
  overflow: auto !important

.button
  margin-left: auto
  display: block
  margin-top: 20px
