@import ../../../styles/helpers

.head
    max-width: 455px
    margin: 0 auto 64px
    text-align: center
    +d
        margin-bottom: 48px

.title
    margin-bottom: 20px

.info
    +body-2
    color: $n3
    +dark
        color: $n5

.list
    display: flex
    flex-wrap: wrap
    margin: -48px -16px 0
    +m
        display: block
        margin: 0

.card
    flex: 0 0 calc(33.333% - 32px)
    width: calc(33.333% - 32px)
    margin: 48px 16px 0
    +t
        flex: 0 0 calc(50% - 32px)
        width: calc(50% - 32px)
    +m
        width: 100%
        margin: 32px 0 0
    &:nth-child(n+4)
        display: none
        +t
            display: flex
        +m
            display: none

.btns
    margin-top: 64px
    text-align: center
    +m
        margin-top: 48px

.button
    .loader
        margin: 0 16px 0 5px
        transform: scale(.8)
