
.page_404
  padding: 40px 0
  background: #fff
  font-family: 'Arvo', serif
  height: 100vh

.page_404 img
  width: 100%

.four_zero_four_bg
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif)
  height: 400px
  background-position: center

  & h1
    font-size: 80px
    text-align: center
    color: black

  & h3
    font-size: 80px
    color: black
    text-align: center

.link_404
  color: #080808 !important
  padding: 10px 20px
  background: #39ac31
  margin: 20px 0
  display: block
  margin: 0 auto
  width: 150px
  text-align: center

.contant_box_404
  margin-top: -50px

  & p
    font-size: 20px
    color: black
    text-align: center
  & h1
    font-size: 80px
    text-align: center
    color: black

  & h3
    font-size: 30px
    color: black
    text-align: center
